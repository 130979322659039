import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

const Header = ({ siteTitle, logo }) => (
  <header>
    <div className="header">
      <Link
        to="/"
        className="logo"
        style={{
          color: `black`,
          textDecoration: `none`,
        }}
      >
        <img src={logo} alt="logo" />
      </Link>
      <div className="link">
        <span onClick={() => scrollTo("#home")}>
          <span role="img" aria-label="house">
            🏠
          </span>
          &nbsp;Home
        </span>
        <span onClick={() => scrollTo("#poker-review")}>
          <span role="img" aria-label="card">
            🃏
          </span>
          &nbsp;Poker
        </span>
        <span onClick={() => scrollTo("#casino-review")}>
          <span role="img" aria-label="slot">
            🎰
          </span>
          &nbsp;Casino
        </span>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
